import React from "react";
import { Link } from "gatsby";

import AboutStyles from "./aboutUs.module.css";


import HeaderWithTextAndImage from "../components/headerWithTextAndImage";
import HeaderWithTextAndImageSmall from "../components/headerWithTextAndImageSmall";
import TextWrapAroundImg from "../components/textWrapAroundImg";
import caretRight from "../images/caret-right.png";
import heydiddlediddle from "../images/heydiddlediddle.png";
import Head from "../components/head";


import {graphql} from "gatsby";

export const query = graphql`
query{
  allContentfulAboutUsPage{
    edges{
      node{
        headerImage{
          file{
            url
          }
        }
        headerHeading
        headerSubheading
        headerCaption
        section1Image{
          file{
            url
          }
        }
        section1Headline
        section1Paragraph{
          section1Paragraph
        }
        section2Image{
          file{
            url
          }
        }
        section2Headline
        section2Paragraph{
          section2Paragraph
        }
        section3Headline
        section3Paragraph{
          section3Paragraph
        }
        missionModulesImage{
          file{
            url
          }
        }
        section4Headline
        section4Paragraph{
          section4Paragraph
        }
        missionVideo1Paragraph{
          missionVideo1Paragraph
        }
        missionVideo2Paragraph{
          missionVideo2Paragraph
        }
        section5Image{
          file{url}
        }
        section5Headline
        section5ImageCaption
        section5Paragraph{
          section5Paragraph
        }
        section6Question1
        section6Question2
        section6Question3
        section6Paragraph{
          section6Paragraph
        }
        section7Image{
          file{
            url
          }
        }
        section7Headline{
          section7Headline
        }
        section7Paragraph{
          section7Paragraph
        }
        
        
        
      }
    }
  }  
}`;



class AboutPage extends React.Component {
  componentDidMount(){
    Array.from(document.querySelectorAll('p, h1, h2')).forEach(el => {
  
      let v=el.innerHTML;
      let p1=v.replace("Better Music, Better World.", "<strong style=color:#ba0c2f; font-weight:600>Better Music, Better World.</strong>")
      el.innerHTML=p1;
      });
    // target only ones that have the correct text to be replaced instead of searching for all
Array.from(document.querySelectorAll('p, h1, h2')).forEach(el => {
  
  let v=el.innerHTML;
  let p1=v.replace("Better Music, Better World", "<strong style=color:#ba0c2f; font-weight:600>Better Music, Better World</strong>")
  el.innerHTML=p1;
  });
  
  Array.from(document.querySelectorAll('p')).forEach(el => {
   
    let v=el.innerHTML;
    let p1=v.replace("Better Music", "<strong style=color:#ba0c2f; font-weight:600>Better Music</strong>")
    el.innerHTML=p1;
    });

    Array.from(document.querySelectorAll('p')).forEach(el => {
   
      let v=el.innerHTML;
      let p1=v.replace("Better World", "<strong style=color:#ba0c2f; font-weight:600>Better World</strong>")
      el.innerHTML=p1;
      });

  }
render(){
  return (
    <>
    <Head title="About Us"></Head>
    <div
        className={AboutStyles.backgroundImageContainer}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${this.props.data.allContentfulAboutUsPage.edges[0].node.headerImage.file.url})`,
        }}
      >
        <div>
          <h1 className={AboutStyles.heading}>
          {this.props.data.allContentfulAboutUsPage.edges[0].node.headerHeading}
          </h1>
          <p className={AboutStyles.subHeading}>
         {this.props.data.allContentfulAboutUsPage.edges[0].node.headerSubheading}
          <br/>
          <span style={{fontSize:"12px"}}>{this.props.data.allContentfulAboutUsPage.edges[0].node.headerCaption}</span>
          </p>
         
        </div>
      </div>
      <div className={AboutStyles.aboutPoemStanza}>
        <div>
          <div>
            <h2>
              We dance round in a ring and suppose, <br></br>But the Secret sits in the
              middle and knows.
            </h2>
            <p>We take our name from this short poem by Robert Frost.</p>
          </div>
        </div>
      </div>
      <HeaderWithTextAndImageSmall
        paragraphHeader={this.props.data.allContentfulAboutUsPage.edges[0].node.section1Headline}
        imgRight={this.props.data.allContentfulAboutUsPage.edges[0].node.section1Image.file.url}
        isFirstElement={false}
        lightBackground={true}
      >
        <p  style={{whiteSpace:"pre-wrap"}}>
              {this.props.data.allContentfulAboutUsPage.edges[0].node.section1Paragraph.section1Paragraph}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginTop: "15px",
              }}
            >
              <Link
                to="/about-marie"
                style={{
                  textDecoration: "none",
                  color: "#ba0c2f",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Read about Marie
              </Link>
            </div>
            </HeaderWithTextAndImageSmall>
      {/* <div  style={{backgroundColor:"#fff"}}>
      <div className={headlineStyles.headlineLeft}>
      <h2>
      We exist to make a difference in individual lives
      </h2>
    </div>
    </div> */}
       <HeaderWithTextAndImage
        paragraphHeader={this.props.data.allContentfulAboutUsPage.edges[0].node.section2Headline}
        imgRight={this.props.data.allContentfulAboutUsPage.edges[0].node.section2Image.file.url}
        isFirstElement={false}
        lightBackground={true}
      >
        <p style={{whiteSpace:"pre-wrap"}}>
         {this.props.data.allContentfulAboutUsPage.edges[0].node.section2Paragraph.section2Paragraph} </p>
      </HeaderWithTextAndImage>
      
      <section className={AboutStyles.missionSection}>
        <div>
          <h2>{this.props.data.allContentfulAboutUsPage.edges[0].node.section3Headline}</h2>
          <p  style={{whiteSpace:"pre-wrap"}}>
           {this.props.data.allContentfulAboutUsPage.edges[0].node.section3Paragraph.section3Paragraph}
          </p>
          <p id={AboutStyles.imgHeading}>
            The following schematic diagram depicts the elements in our game
            plan.
          </p>
          <img src={this.props.data.allContentfulAboutUsPage.edges[0].node.missionModulesImage.file.url} alt="mobius strip"></img>
        </div>
      </section>
      <section className={AboutStyles.missionMotivation}>
        <div>
          <h2>{this.props.data.allContentfulAboutUsPage.edges[0].node.section4Headline}</h2>
          <p  style={{whiteSpace:"pre-wrap"}}>
           {this.props.data.allContentfulAboutUsPage.edges[0].node.section4Paragraph.section4Paragraph}
          </p>
          <div><iframe className={AboutStyles.videoAttr} src="https://www.youtube.com/embed/ol-8XCbpwf0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          <p  style={{whiteSpace:"pre-wrap"}}>
            {this.props.data.allContentfulAboutUsPage.edges[0].node.missionVideo1Paragraph.missionVideo1Paragraph}
          </p>
          <div><iframe className={AboutStyles.videoAttr} src="https://www.youtube.com/embed/3vaqa1L-Mz0" frameborder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
          <p >
            {this.props.data.allContentfulAboutUsPage.edges[0].node.missionVideo2Paragraph.missionVideo2Paragraph}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              padding:"0px"
            }}
          >
            <Link
              to="/donate"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
            >Donate to help musicians and would-be musicians like these{" "}

              <img
                src={caretRight}
                style={{ height: "9px", marginLeft: "4px", width: "auto" }}
              ></img>
            </Link>
          </div>
        </div>
      </section>
      <TextWrapAroundImg
        imgRight={this.props.data.allContentfulAboutUsPage.edges[0].node.section5Image.file.url}
        paragraphHeader={this.props.data.allContentfulAboutUsPage.edges[0].node.section5Headline}
        imgText={this.props.data.allContentfulAboutUsPage.edges[0].node.section5ImageCaption}
        lightBackground={true}
      > <p style={{whiteSpace:"pre-wrap"}}>
      {this.props.data.allContentfulAboutUsPage.edges[0].node.section5Paragraph.section5Paragraph}
    </p></TextWrapAroundImg>
      
      <HeaderWithTextAndImage
        paragraphHeader={false}
        imgRight={heydiddlediddle}
        isFirstElement={false}
        lightBackground={true}
        noPadding={true}
      >
         <p>Remember that old (16th Century) nursery rhyme about cats and fiddles,
           and cows and moons?  Here's a Secret-Sits version of it that holds some
            entertainment interest, while also acknowledging the probable futility
             of any kind of metaphysical understanding coming out of anything we might
              attempt on whatever ground there is between music and nature:</p>
      
      <p style={{marginLeft:"20px"}}>
      Hey Diddle, Diddle the cat and the fiddle<br/>
The cow jumps over the moon<br/>
The little dog sighs, the Secret never dies<br/>
The dish runs away with the spoon<br/>
The Secret sits and knows in the middle<br/>
But it won't be revealed too soon.
      </p>
      <p>
      Hopeless, yes; but we can still surmise it is a property of the life-force
       itself relentlessly to push against intransigent ignorance at the boundary 
       between living and nonliving existence until, ... until, in some faraway age 
       ahead a first domino might fall, and a first glimmer of universal light start
        to shimmer out of the darkness.  So, join us if you will <Link style={{color:"inherit", textDecoration:"none"}} to="/contact-us"><strong style={{fontWeight:"600", color:"#ba0c2f"}}>contact us</strong></Link>, and bring 
        your own thoughts, facts, and speculations to this table of our key lofty questions.  
      </p>
      </HeaderWithTextAndImage>
      
      
    
      
      <section className={AboutStyles.scienceCont} style={{backgroundColor:"#fff"}}>
        <h2>{this.props.data.allContentfulAboutUsPage.edges[0].node.section6Question1}</h2>
            <h2>{this.props.data.allContentfulAboutUsPage.edges[0].node.section6Question2}</h2>
            <h2 id={AboutStyles.scienceContH2Last}> {this.props.data.allContentfulAboutUsPage.edges[0].node.section6Question3}</h2>
            <div>
              
                <p  style={{whiteSpace:"pre-wrap"}}>
                  {this.props.data.allContentfulAboutUsPage.edges[0].node.section6Paragraph.section6Paragraph}
                </p>
              
            </div>
      </section>
      <HeaderWithTextAndImage
        paragraphHeader={false}
        imgRight={this.props.data.allContentfulAboutUsPage.edges[0].node.section7Image.file.url}
        isFirstElement={false}
        lightBackground={true}
        offsetImg={true}
      >
        <h2>{this.props.data.allContentfulAboutUsPage.edges[0].node.section7Headline.section7Headline}</h2>
        <p>
      {this.props.data.allContentfulAboutUsPage.edges[0].node.section7Paragraph.section7Paragraph}
    </p>
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <Link
        to="/donate"
        style={{
          textDecoration: "none",
          color: "#ba0c2f",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
        }}
      >
        Help us{" "}
        <img
          src={caretRight}
          style={{ height: "9px", marginLeft: "4px", width: "auto" }}
        ></img>
      </Link>
    </div></HeaderWithTextAndImage>
      
    </>
  );
}
}
export default AboutPage;